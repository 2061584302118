import { useGetInAppMessages } from '@commonstock/common/src/api/feed'
import { isLoggedIn } from '@commonstock/common/src/auth'
import React from 'react'
import { InAppMessage } from './InAppMessage'

function InAppMessageList() {
  const [messages] = useGetInAppMessages(null, { paused: !isLoggedIn() })
  if (!messages) return null
  return (
    <>
      {messages.map(message => (
        <InAppMessage key={message.uuid} message={message} />
      ))}
    </>
  )
}

export default InAppMessageList
