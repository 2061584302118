import React from 'react'
import { TextBodyRegular } from '../../composing/CSText'
import { Spacing } from '../../composing/Spacing'

function AllCaughtUp() {
  return (
    <Spacing margin={[4, 0, 0]}>
      <TextBodyRegular block centered>
        You&apos;re all caught up!
      </TextBodyRegular>
    </Spacing>
  )
}

export default AllCaughtUp
